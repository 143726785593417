@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~@fortawesome/fontawesome-free/css/all.css";
// @import "~@angular/material/theming";

// Include the core theme styles
// @include mat-core();

body {
  margin: 0;
  background-color: #fafafa;
  -webkit-user-select: none; /* Disable selection for Chrome, Safari, and Opera */
  -moz-user-select: none; /* Disable selection for Firefox */
  -ms-user-select: none; /* Disable selection for Internet Explorer and Edge */
  user-select: none; /* Disable selection for most browsers */
}

.arabic {
  font-family: "Cairo" !important;
}

.english {
  font-family: "Raleway" !important;
}
.ql-font-cairo {
  font-family: "Cairo", sans-serif !important;
}
.ql-font-raleway {
  font-family: "Raleway", sans-serif !important;
}

.center {
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.hover-effect {
  fill: #9ab9d6 !important;
  fill-opacity: 0.6 !important;
}

.selectedEffect {
  border: dashed 2px #e60027;
}

.description-box {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  /* Additional styles for positioning and appearance */
}

.floating-box {
  width: 250px;
  position: fixed;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  pointer-events: none;
  transition: transform 0.1s ease-out;
  transform-origin: top left;
}
.text-xxs {
  font-size: 10px;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #e60027;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #e60027;
}

.rounded-5xl {
  border-radius: 2.4rem;
}

.mainColor {
  color: #e60027 !important;
}
.mdc-notched-outline__notch {
  border-right: none;
}
.mat-expansion-indicator::after {
  border-color: #e60027 !important;
}

.mat-expansion-panel-expanded .mat-expansion-indicator::after {
  border-color: #e60027 !important;
}
.mdc-list-item__primary-text {
  width: 100% !important;
}
